/*
    font-family: 'Muli', sans-serif;

*/
html,
body {
  overflow-x: hidden;
}

body {
  color: #616161;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  font-family: "Nunito Sans", sans-serif;
}

p ,.inbox_text {
  font-weight: 400;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #232323;
  font-family: "Muli", sans-serif;
  font-style: normal;
  margin-bottom: 0;
  margin-top: 11px;
  line-height: 1.2;
  font-weight: 700;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 36px;
}

h1 {
}

h2 {
}

h3 {
}

h4 {
}

h5 {
}

h6 {
}

a {
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

a {
  color: #118ce9;
}

a:focus,
.btn:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  text-decoration: none;
  text-decoration: none;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

h1 {
  font-size: 42px;
}

h2 {
  font-size: 35px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 18px;
}

.white h1,
.white h2,
.white h3,
.white h4,
.white h5,
.white h6 {
  color: #fff;
}

.white p {
  color: #fff;
}

ul {
  margin: 0px;
  padding: 0px;
}

li {
}

ul {
  margin: 0;
  padding: 0;
}

li {
}

input:focus,
button:focus {
  border-color: transparent;
  outline-color: transparent;
}

textarea:focus {
  border-color: transparent;
  outline-color: transparent;
}

hr {
  margin: 60px 0;
  padding: 0px;
  border-bottom: 1px solid #eceff8;
  border-top: 0px;
}

hr.style-2 {
  border-bottom: 1px dashed #f10;
}

hr.mp-0 {
  margin: 0;
  border-bottom: 1px solid #eceff8;
}

hr.mtb-40 {
  margin: 40px 0;
  border-bottom: 1px solid #eceff8;
  border-top: 0px;
}

label {
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}

a,
i,
cite,
em,
var,
address,
dfn,
ol,
li,
ul {
  font-style: normal;
  font-weight: 400;
}

/* Default Background */

.bg_color {
  background: #118ce9;
}

.bg_color2 {
  background: #eff2f7;
}

.bg_color3 {
  background: #f5f8fd;
}

.bg_color4 {
  background: #010101;
}

.white_bg {
  background: #fff;
}

.text_center {
  text-align: center;
}

.bg_fixed {
  background-attachment: fixed;
}

.bg_before {
  position: relative;
}

.bg_before:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(12, 90, 219, 0.3);
}

.nagative_mb {
  position: relative;
  margin-bottom: -180px;
  z-index: 1;
}

*::-moz-selection {
  background: #4fc1f0;
  color: #454545;
  text-shadow: none;
}

::-moz-selection {
  background: #4fc1f0;
  color: #454545;
  text-shadow: none;
}

::selection {
  background: #4fc1f0;
  color: #454545;
  text-shadow: none;
}

::placeholder {
  color: #454545;
  font-size: 16px;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #454545;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #454545;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #454545;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #454545;
}

/* Contact Area */

.counter_area ::-moz-selection {
  contact_area: #fff;
  text-shadow: none;
}

.contact_area ::-moz-selection {
  color: #fff;
  text-shadow: none;
}

.contact_area ::selection {
  color: #fff;
  text-shadow: none;
}

.contact_area ::placeholder {
  color: #fff;
  font-size: 16px;
}

.contact_area ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
}

.contact_area ::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}

.contact_area :-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
}

.contact_area :-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}

.blog_reply ::-moz-selection {
  color: #151948;
}

.blog_reply ::-moz-selection {
  color: #151948;
}

.blog_reply ::selection {
  color: #151948;
}

.blog_reply::placeholder {
  color: #151948;
}

.blog_reply ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #151948;
}

.blog_reply ::-moz-placeholder {
  /* Firefox 19+ */
  color: #151948;
}

.blog_reply :-ms-input-placeholder {
  /* IE 10+ */
  color: #151948;
}

.blog_reply :-moz-placeholder {
  /* Firefox 18- */
  color: #151948;
}

/* Padding Top Css */

.pt-280 {
  padding-top: 280px;
}

.pt-250 {
  padding-top: 250px;
}

.pt-200 {
  padding-top: 200px;
}

.pt-190 {
  padding-top: 190px;
}

.pt-180 {
  padding-top: 180px;
}

.pt-170 {
  padding-top: 170px;
}

.pt-160 {
  padding-top: 160px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-10 {
  padding-top: 10px;
}

/* Padding Bottom Css */

.pb-250 {
  padding-bottom: 250px;
}

.pb-200 {
  padding-bottom: 200px;
}

.pb-190 {
  padding-bottom: 190px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-160 {
  padding-bottom: 160px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-135 {
  padding-bottom: 135px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-90 {
  padding-bottom: 145px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-10 {
  padding-bottom: 10px;
}

/* Padding Left Css */

.pl-120 {
  padding-left: 120px;
}

.pl-110 {
  padding-left: 110px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-10 {
  padding-left: 10px;
}

/* Padding Right Css */

.pr-120 {
  padding-right: 120px;
}

.pr-110 {
  padding-right: 110px;
}

.pr-100 {
  padding-right: 100px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-10 {
  padding-right: 10px;
}

/* Margin Top Css */

.mt-200 {
  margin-top: 200px;
}

.mt-190 {
  margin-top: 190px;
}

.mt-180 {
  margin-top: 180px;
}

.mt-170 {
  margin-top: 170px;
}

.mt-160 {
  margin-top: 160px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-10 {
  margin-top: 10px;
}

/* Margin Bottom Css */

.mb-200 {
  margin-bottom: 200px;
}

.mb-190 {
  margin-bottom: 190px;
}

.mb-180 {
  margin-bottom: 180px;
}

.mb-170 {
  margin-bottom: 170px;
}

.mb-160 {
  margin-bottom: 160px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-10 {
  margin-bottom: 10px;
}

/* Margin Left Css */

.ml-200 {
  margin-left: 200px;
}

.ml-190 {
  margin-left: 190px;
}

.ml-180 {
  margin-left: 180px;
}

.ml-170 {
  margin-left: 170px;
}

.ml-160 {
  margin-left: 160px;
}

.ml-150 {
  margin-left: 150px;
}

.ml-140 {
  margin-left: 140px;
}

.ml-130 {
  margin-left: 130px;
}

.ml-120 {
  margin-left: 120px;
}

.ml-110 {
  margin-left: 110px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-95 {
  margin-left: 95px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-65 {
  margin-left: 65px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-55 {
  margin-left: 55px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-10 {
  margin-left: 10px;
}

/* Margin Right Css */

.mr-200 {
  margin-right: 200px;
}

.mr-190 {
  margin-right: 190px;
}

.mr-180 {
  margin-right: 180px;
}

.mr-170 {
  margin-right: 170px;
}

.mr-160 {
  margin-right: 160px;
}

.mr-150 {
  margin-right: 150px;
}

.mr-140 {
  margin-right: 140px;
}

.mr-130 {
  margin-right: 130px;
}

.mr-120 {
  margin-right: 120px;
}

.mr-110 {
  margin-right: 110px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-95 {
  margin-right: 95px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-75 {
  margin-right: 75px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-65 {
  margin-right: 65px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-55 {
  margin-right: 55px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-10 {
  margin-right: 10px;
}

/* Margin - Top Css */

.mrt-345 {
  margin-top: -345px;
}

.mrt-300 {
  margin-top: -300px;
}

.mrt-200 {
  margin-top: -200px;
}

.mrt-100 {
  margin-top: -100px;
}

.mrt-50 {
  margin-top: -50px;
}

.react-bootstrap-table-pagination .dropdown-menu.show {
  bottom: 100%;
}
