.text {
    color: white;
    font-weight: 200; 
    font-size: 16px;
    margin-top:20px
}
.mail {
    position: absolute;
    left: 6px;
    top: 50%;
    transform: translateY(-50%);
    border: 2px solid #ffff;
    border-radius: 20px;
    height: 73%;
    width: 29px;
    text-align: center;
    line-height: 24px;
    font-size: 13px;
    overflow: hidden;
    transition: all 0.5s ease;
}
.fa-envelope:before{
    color:white;
}
.mail:hover{
    background-color: #ee6419;
    font-size:15px;
}
.input::placeholder {
    color: black; 
}
.input {

    width: 65%;
    border: 2px solid #fff;
    border-radius: 10px;
    height: 40px;
  
    padding: 10px;
    box-sizing: border-box;
    background-color: #fff;
}

.links{
    justify-content: center;
    display: flex;    top: -46px;
    position: relative;
}
.menu li {
    position: relative;
}
  .follow-company-icon {
    top: -16PX;
    position: relative;
	height: 11vh;
	display: flex;
	align-items: center;
	justify-content: center;
  }
  .follow-company-icon i{
    margin: auto;
	font-size: 24px;
	color: #67798e;
	z-index: 1; 
	transition: var(--trans-property);
  }
  .follow-company-icon a {
    display: inline-block;
    margin-left: 10px; 
    display: flex;
	position: relative;
	overflow: hidden; 
    width: 47px;
    height: 46px;
	margin: 8px;
	background-color: white;
	border-radius: 50%;
	box-shadow: 0px 1px 3px rgba(0,0,0,0.12);
	text-decoration: none;
    transition: background-color 0.3s ease;
	/* transition: var(--trans-property);Adjust the spacing between icons as needed */
  }
  .follow-company-icon a:after{
    content: "";
	width: var(--link-size);
	height: var(--link-size);
	position: absolute;
	transform: translate(0, var(--link-size));
	border-radius: 50%;
	transition: var(--trans-property);
  }
  
  .follow-company-icon a i {
    font-size: 24px;
    color: #67798e;
    transition: transform 0.3s ease, color 0.5s ease;
    margin: auto;
	font-size: 24px;
	color: #67798e;
	z-index: 1; 
  }
  .follow-company-icon a:hover i {
    /* color: #fff; */
    
    transform: scale(1.2);
  }
  .footer .fa-brands {
    color: #fff;
    transition: color 0.3s ease;
  }
  .follow-company-icon :hover i {
	color: #fff;
}

.follow-company-icon a:hover {
    transform: translateY(-4px);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
}
.follow-company-icon:hover:after {
	transform: translate(0) scale(1.2);
}

.bt_subscribe{
    background-color: #118CE9;
      border: none;
    
      padding: 6px 20px 6px 20px;
      width: auto;
      border-radius: 10px;
      color: white;
  }
 

.social-icon-footer .linkedin:hover {
    color: #0072b1 !important;
  }
  .social-icon-footer .facebook:hover{
    color:#1877F2 !important;
  }
  .social-icon-footer .instagram:hover{
color: #d62976  !important;
  }
  .social-icon-footer .whatsapp:hover{
    color: #25D366 !important;
  }
.footer-bottom-content-copy p{
    justify-content: center;
    display: flex;
    text-align: center;
    
    padding-top: 25px;
}
.footer-social-info p,
.footer-social-info a {
  color: #fff;
  text-decoration: none;
  top: -5px;
  left: -2px;
  font-weight: lighter;
}
.slider_button{
    justify-content: center;
    align-items: center;
}
@media (min-width: 1200px) and (max-width: 1500px){.follow-company-icon{    top: -13PX;}}
@media (min-width: 768px) and (max-width: 991px) {
    .links{
        top:0px;
    }
    .follow-company-icon{
        top:0px;
    }
    .footer-social-info p{
        top: -27px;
        left: 0px;
    }
    .input{
        width:56%;
    }

}
@media (min-width: 992px) and (max-width: 1199px) {
    .follow-company-icon{
        left: -18px;
        top:0px;
    }
}
@media (min-width: 768px) and (max-width: 991px){ 
    .follow-company-icon { 
           left: -24px;
        } }

        @media only screen and (min-width: 320px) and (max-width: 599px){
            .links{
                top: 30px;
                line-height: 22px;
                justify-content: left;

            }
            .footer_middle_area  .container .row{
                margin-top:0px;
            }
            .footer_middle_area {
                padding-top:10px;
            }
            .footer-social-info p{
                display: flow !important;
            } 
            .footer-social-info p{
                top: -18px;
                left: -2px;
                font-size: 12px;
              }
              .follow-company-icon a{
                width: 34px;
                height: 34px;
                top: -8px;
              }
              .widgets-company-info{
                top: 50px;
    position: relative;
              }
              .follow-company-icon{
                top: -50px;
                left: -42px;
              }
              .text{
                font-size: 13px ;
              }
              .social-icon-footer li{
                padding-right: 10px !important;
                padding-left:0px !important;
              }
              .menu-quick-link-container ul li{
                font-size:14px;
              }
        }

      